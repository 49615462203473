.container {
  max-width: 1300px !important;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 1300px) {
  .container {
    padding: 0 1.5rem;
  }
}
@media (max-width: 430px) {
  body {
      overflow-x: hidden;
      width: 100%;
      max-width: 100vw;
  }
  .container {
      overflow-x: hidden;
  }
}