.contact-details-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #130f2a;
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    padding: 1.5rem;
    margin-bottom: 2rem;
    transition: border-color 0.7s ease;
}

.contact-details-card:hover {
    border-color: #ffffff;
}

.contact-details-card .icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background: #3d3072;
    margin-bottom: 0.8rem;
    text-decoration: none;
    color: white;
}

.contact-details-card .icon p {
    font-size: 0.7rem;
    margin-top: 0.5rem;
    color: white;
    text-align: center;
}