.hero-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 95px;
    margin-top: -60px;
}
.hero-container::after,
.hero-container::before {
    content: " ";
    width: 26rem;
    height: 26rem;
    border-radius: 28.125rem;
    background: #7c66e3;
    position: absolute;
    z-index: -1;
    filter: blur(225px);
}
.hero-container::after {
    top: -3rem;
    left: -5rem;
}
.hero-container::before {
    bottom: 2rem;
    right: 1rem;
}
.hero-content {
    flex: 1;
    order: 0;
}
.hero-content h2 {
    font-size: 3.8rem;
    font-weight: 600;
    line-height: 5rem;
    margin-bottom: 1rem;
}
.hero-content p {
    width: 80%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
}
.nombre {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 1rem;
}
.hero-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 5rem;
}
.hero-img > div {
    display: flex;
    align-items: flex-end;
    gap: 2rem;
}
.hero-img > div > img {
    width: 35rem;
    transition: all 0.3s ease;
}
.tech-icon {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 0.65rem;
    border: 1px solid #6852ba;
    order: 1;
    transition: all 0.3s ease;
}
.tech-icon .icon {
    font-size: 3.5rem;
    color: inherit;
}
@keyframes circle {
    0% {
        transform: rotate(0deg) translate(10px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translate(10px) rotate(-360deg);
    }
}
.hero-img img:hover,
.tech-icon:hover {
    animation: circle 1s;
}
@media (max-width: 1025px) {
    .hero-content h2 {
        font-size: 3rem;
        line-height: 4rem;
    }
    .hero-content p {
        font-size: 0.9rem;
        line-height: 1.6rem;
    }
    .hero-img > div > img {
        width: 20rem;
    }
    .tech-icon {
        width: 4rem;
        height: 4rem;
    }
    .tech-icon .icon {
        font-size: 2.5rem;
    }
}
@media (max-width: 768px) {
    .hero-container {
        flex-direction: column;
    }
    .hero-img {
        margin: 2rem 0 2rem 0;
    }
    .hero-container {
        margin-top: 3rem;
    }
    .hero-content h2,
    .hero-content p {
        width: auto;
        text-align: center;
    }
    .hero-container::after,
    .hero-container::before {
        content: " ";
        width: 18rem;
        height: 18rem;
    }
    .hero-container::after {
        top: 0rem;
        left: 0rem;
    }
    .hero-container::before {
        bottom: 2rem;
        right: 0rem;
    }
    .tech-icon .icon {
        font-size: 2.5rem;
    }
}
@media (max-width: 600px) {
    .hero-content h2 {
        font-size: 2.2rem;
        line-height: 3rem;
    }
    .hero-content p {
        font-size: 0.8rem;
        line-height: 1.3rem;
    }
    .hero-img {
        gap: 2rem;
    }
    .hero-img > div {
        gap: 1.5rem;
    }
    .hero-img > div > img {
        width: 14rem;
    }
    .tech-icon {
        width: 3.3rem;
        height: 3.3rem;
    }
    .tech-icon .icon {
        font-size: 2rem;
    }
}
@media (max-width: 425px) {
    .hero-container::after,
    .hero-container::before {
        content: " ";
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        background: #7c66e3;
        position: absolute;
        z-index: -1;
        filter: blur(100px);
    }
    .hero-container::after {
        top: 0;
        left: 10px;
    }
    .hero-container::before {
        bottom: 0;
        right: 0;
    }
    .hero-content h2 {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
    .hero-content p {
        font-size: 0.7rem;
        line-height: 1.1rem;
    }
    .hero-img > div > img {
        width: 12rem;
    }
    .tech-icon {
        width: 2.5rem;
        height: 2.5rem;
    }
    .tech-icon .icon {
        font-size: 1.5rem;
    }
}
@media (max-width: 375px) {
    .imgicons {
        margin-left: 10px;
    }
    .hero-content h2 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .hero-content p {
        font-size: 0.6rem;
        line-height: 1rem;
    }
    .hero-img > div > img {
        width: 10rem;
    }
    .tech-icon {
        width: 2rem;
        height: 2rem;
    }
    .tech-icon .icon {
        font-size: 1rem;
    }
}