.contact-container {
    margin: 4rem 1rem;
    position: relative;
    padding-top: 155px;
    margin-top: 10px;

}
.contact-container::before {
    content: '';
    width: 28rem;
    height: 28rem;
    border-radius: 28.125rem;
    background: #7b6eb4;
    position: absolute;
    z-index: -1;
    filter: blur(180px);
    bottom: 2px;
    top: 1px;
}
.contact-container h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
    text-align: center;
}
.contact-content {
    display: flex;
    gap: 3rem;
}
@media (max-width: 768px) {
    .contact-content {
        flex-direction: column-reverse;

    }
    .contact-container h5 {
        font-size: 1.3rem;
        margin-bottom: 0rem;
    }
}
.contact-details-card {
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgb(255, 255, 255);
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
@media (max-width: 430px) {
    .contact-details-card {
        margin-left: -1.5rem;
    }

    .contact-container h5 {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}