.work-experience-card,
.work-name {
    background: #130f2a;
    border-radius: 1.5px solid #6751b9;
    padding: 1.5rem;
    margin: 0 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.work-name {
    display: grid;
    font-size: 1.5rem;
    font-weight: 400;
    background: rgba(103, 81, 185, 0.5);
    border-radius: 0.3rem;
    padding: 0.4rem 0.6rem;
    margin-bottom: 1.3rem;
    
}
.work-experience-card img {
    width: 100%;
    height: auto;
    border-radius: 1.5px solid #6751b9;
    justify-self: center;
    transition: transform 0.3s ease-in-out;
}
.work-experience-card img:hover {
    transform: scale(1.2);
}
.work-experience-card h6 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.7rem;
}
.work-description {
    font-size: 1.2rem;
}
.work-url {
    font-size: 1.2rem;
    color: #6751b9;
    text-decoration: none;
    transition: 0.3s;
}
.work-url:hover {
    color: #f5f5f5;
}

@media (max-width: 768px) {
    .work-experience-card {
        margin: 0;
    }
    .work-experience-card h6 {
        font-size: 0.95rem;
    }
    
}
.work-skills {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    font-size: 1.2rem;
    color: #f5f5f5;
    text-decoration: none;
    transition: 0.5s;
}
.work-icon {
    font-size: 2rem;
    color: #f5f5f5;
}
.work-skills:hover {
    color: #6751b9;
}