.logo {
    width: 8rem;
    height: auto;
    margin: 20px 10px;
    padding: 0;
    border-radius: 40%;
    background-color: #251b4e;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.3s;
    
}
.nav-wrapper {
    background: rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1;
    position: sticky;
    top: 0;
    z-index: 2;
    backdrop-filter: blur(50px);
    
    
}
.nav-content {
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 auto;
}
.nav-content ul {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
}
.nav-content li {
    margin: 0 1.5rem;
}
.menu-item {
    font-size: 0.9rem;
    text-decoration: none;
    font-weight: 500;
    position: relative;
    color: #fff;
    cursor: pointer;
}
.menu-item:before {
    content: " ";
    width: 2rem;
    height: 0.2rem;
    background: linear-gradient(90deg, #433674, #7e61e7 100%);
    border-radius: 0.5rem;
    position: absolute;
    bottom: -0.5rem;
    opacity: 0;
    transform:translate(-1.5rem);
    transition: all 0.3s ease;
}
.menu-item:hover:before {
    opacity: 1;
    width: 100%;
    transform:translate(0);
}
.menu-btn {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    color:#fff;
    background: linear-gradient(90deg, #433674, #7e61e7 100%);
    line-height: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    display: none;
}
.menu-btn:hover {
    color: #a993fe;
    background: #000;
    border: 1px solid #a993fe;
}
@media (max-width: 425px) {
    .logo {
        width: auto;
        height: auto;
    }
    .nav-wrapper {
        position: relative;
        background: transparent;
    }
    .nav-wrapper::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #7c66e3;
        filter: blur(130px);
        z-index: -1;
    }
}
@media (max-width: 769px) {
    .menu-btn {
        display: block;
    }
    .nav-content ul {
        display: none;
    }
}
@media (max-width: 1325px) {
    .nav-wrapper {
        padding: 0 2rem;
    }
}
@media (max-width: 430px) {
    .logo {
        width: 6rem;
        font-size: 2rem;
    }
    .nav-wrapper {
        position: relative;
        background: transparent;
    }
    .nav-wrapper::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0px;
        width: 100%;
        height: 100%;
        background: #7c66e3;
        filter: blur(200px);
        z-index: -1;
    }

}
.logo {
    width: 90px;
    height: auto;
}